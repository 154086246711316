import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { loginStarted } from '../msal/actions/msal-login.actions';
import { MsalState } from '../msal/reducers/msal.reducers';
import { Subscriptions, SubscriptionTypes } from '../shared/subscription-types/subscriptions.constants';

@Component({
    selector: 'dead-purchase-confirmation',
    templateUrl: './purchase-confirmation.component.html',
    styleUrls: ['./purchase-confirmation.component.scss']
})
export class PurchaseConfirmationComponent implements OnInit {
    faCheckCircle = faCheckCircle;
    isBulkPurchase = false;
    sendCheck = false;
    faArrowRight = faArrowRight;
    subEndDate: Date = null;
    subscriptionName = '';

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private msalStore: Store<MsalState>
    ) { }

    ngOnInit(): void {
        if (!this.route.snapshot.queryParams.subscriptioncode) {
            this.router.navigate(['/error'])
        }

        this.isBulkPurchase = JSON.parse(this.route.snapshot.queryParams.isbulkpurchase);
        this.sendCheck = JSON.parse(this.route.snapshot.queryParams.sendcheck);
        if (this.route.snapshot.queryParams.subenddate) {
            this.subEndDate = new Date(this.route.snapshot.queryParams.subenddate);
        }
        const subscription = Subscriptions.find(p => p.code.toLowerCase() ===
            this.route.snapshot.queryParams.subscriptioncode.toLowerCase());
            if (subscription.code === SubscriptionTypes.SIX_MONTHS_BULK){
                this.subscriptionName = 'Six Months';
            }
            if (subscription.code === SubscriptionTypes.YEAR_BULK) {
                this.subscriptionName = 'One Year';
            }
    }

    goToStudentDashboard() {
        this.msalStore.dispatch(loginStarted({ redirectUrl: '/student' }));
    }
}
