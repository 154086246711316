import { Injectable } from "@angular/core";
import { User } from "../../../core/models/user.model";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { io, Socket } from "socket.io-client";
import {
  CategoryWithQuestion,
  GameAnswer,
  GameSetting,
  IResponse,
  NewGame,
  SocketConnection,
} from "../interfaces";
import { map } from "rxjs/operators";
import { QuestionCategory } from "../../../core/models/questionCategory.model";
import { Question } from "../../../core/models/question.model";

const INITIAL_GAME_SETTING: GameSetting = {
  playerType: null,
  playerCount: null,
  schedule: null,
  sentInvitation: {},
};

@Injectable({
  providedIn: "root",
})
export class GameSocketService {
  private readonly _socketUrl = environment.GAME_SOCKET_URL;
  private _socket?: Socket;
  private _user = new BehaviorSubject<SocketConnection | null>(null);
  private _initiated$ = new BehaviorSubject<boolean>(false);
  private _game$ = new BehaviorSubject<NewGame | null>(null);
  private _configSetting$ = new BehaviorSubject<GameSetting>(
    INITIAL_GAME_SETTING
  );
  private _onlineUsers$ = new BehaviorSubject<Record<string, SocketConnection>>(
    {}
  );

  init(user: User, token: string): void {
    console.log("=============user", user);
    const name = `${user.firstName} ${user.lastName || ""}`.trim();
    this._socket = io(`${this._socketUrl}?token=${token}`, {
      transports: ["websocket"],
    });
    this._socket.on("connectionResponse", (res) => {
      if (res.success) {
        this._user.next({
          userId: user.userId,
          name,
          email: user.email,
          schoolId: user.schoolId,
          socketId: "",
        });
        this._initiated$.next(true);
      }
    });

    this._currentlyOnline().subscribe((res) => {
      const users: Record<string, SocketConnection> = {};
      res.forEach((item) => {
        users[item.userId] = item;
      });
      this._onlineUsers$.next(users);
    });

    this.onSomeoneJoin().subscribe((res) => {
      console.log("============join", res);
    });

    this.onSomeoneDisconnect().subscribe((res) => {
      console.log("============Disconnect", res);
    });
  }

  getCurrentUser(): SocketConnection {
    const val = this._user.value;
    if (!val) throw "User not initiated yet";
    return val;
  }

  get initiated$(): Observable<boolean> {
    return this._initiated$.asObservable();
  }

  isInitiated(): boolean {
    return this._initiated$.value;
  }

  get onlineUsersList$(): Observable<SocketConnection[]> {
    return this._onlineUsers$
      .asObservable()
      .pipe(map((res) => Object.values(res)));
  }

  get game$(): Observable<NewGame | null> {
    return this._game$.asObservable();
  }

  getGame(): NewGame | null {
    return this._game$.value;
  }

  setGame(game: NewGame | null): void {
    return this._game$.next(game);
  }

  onSomeoneJoin(): Observable<SocketConnection> {
    if (!this._socket) throw "Read event before the initialization";
    return new Observable<any>((observer) => {
      this._socket.on(
        "newConnectionJoin",
        (res: IResponse<SocketConnection>) => {
          // if (res.success && res.data.socketId !== this._socket.id) {
          if (res.success && res.data.userId !== this._user.value?.userId) {
            observer.next(res.data);
            const users = this._onlineUsers$.value;
            users[res.data.userId] = res.data;
            this._onlineUsers$.next(users);
          }
        }
      );
    });
  }

  onGameInvitation(): Observable<{ game: NewGame }> {
    if (!this._socket) throw "Read event before the initialization";
    return new Observable<any>((observer) => {
      this._socket.on("gameInvitation", (res: IResponse<{ game: NewGame }>) => {
        if (res.success) {
          observer.next(res.data);
        }
      });
    });
  }

  invitationStatusChange(): Observable<{
    game: NewGame;
    user: SocketConnection;
    status: string;
  }> {
    if (!this._socket) throw "Read event before the initialization";
    return new Observable<any>((observer) => {
      this._socket.on(
        "invitationStatusChange",
        (
          res: IResponse<{
            game: NewGame;
            user: SocketConnection;
            status: string;
          }>
        ) => {
          if (res.success) {
            observer.next(res.data);
          }
        }
      );
    });
  }

  onSomeoneDisconnect(): Observable<SocketConnection> {
    if (!this._socket) throw "Read event before the initialization";
    return new Observable<any>((observer) => {
      this._socket.on(
        "connectionOffline",
        (res: IResponse<SocketConnection>) => {
          console.log("===============2", {
            res: res.data,
            user: this._user.value,
          });
          // if (res.success && res.data.socketId !== this._socket.id) {
          if (res.success && res.data.userId !== this._user.value?.userId) {
            observer.next(res.data);
            const users = this._onlineUsers$.value;
            delete users[res.data.userId];
            this._onlineUsers$.next(users);
          }
        }
      );
    });
  }

  onGameReady(
    gameId: string
  ): Observable<{ game: NewGame; user: SocketConnection }> {
    if (!this._socket) throw "Read event before the initialization";
    return new Observable<any>((observer) => {
      this._socket.on(
        gameId + "-ready",
        (res: IResponse<{ game: NewGame; user: SocketConnection }>) => {
          observer.next(res.data);
        }
      );
    });
  }

  onGameStart(gameId: string): Observable<{ game: NewGame }> {
    if (!this._socket) throw "Read event before the initialization";
    return new Observable<any>((observer) => {
      this._socket.on(
        gameId + "-start",
        (res: IResponse<{ game: NewGame }>) => {
          observer.next(res.data);
        }
      );
    });
  }

  onGameQuestionSelect(gameId: string): Observable<{ questionId: string }> {
    if (!this._socket) throw "Read event before the initialization";
    return new Observable<any>((observer) => {
      this._socket.on(
        gameId + "-selected-question",
        (res: IResponse<{ questionId: string }>) => {
          observer.next(res.data);
        }
      );
    });
  }

  onGoToTopic(gameId: string): Observable<{ gameId: string }> {
    if (!this._socket) throw "Read event before the initialization";
    return new Observable<any>((observer) => {
      this._socket.on(
        gameId + "-go-to-topic",
        (res: IResponse<{ gameId: string }>) => {
          observer.next(res.data);
        }
      );
    });
  }

  onGameFinish(gameId: string): Observable<{ gameId: string }> {
    if (!this._socket) throw "Read event before the initialization";
    return new Observable<any>((observer) => {
      this._socket.on(
        gameId + "-finish",
        (res: IResponse<{ gameId: string }>) => {
          observer.next(res.data);
        }
      );
    });
  }

  private _currentlyOnline(): Observable<SocketConnection[]> {
    if (!this._socket) throw "Read event before the initialization";
    return new Observable<any>((observer) => {
      this._socket.on(
        "currentlyOnline",
        (res: IResponse<SocketConnection[]>) => {
          if (res.success) {
            observer.next(res.data);
          }
        }
      );
    });
  }

  getUserList(
    search: string,
    schoolIds: string[],
    pageLink: string | null
  ): Observable<IResponse<{ studentList: any[]; nextLink?: string }>> {
    return this._getSocketResponse<
      IResponse<{ studentList: any[]; nextLink?: string }>
    >(`socket.v1.user.list`, {
      search: search || "",
      schoolIds,
      ...(pageLink ? { pageLink } : {}),
    });
  }

  createGame(data: GameSetting): Observable<IResponse<{ game: NewGame }>> {
    return this._getSocketResponse<IResponse<{ game: NewGame }>>(
      `socket.v1.game.create`,
      data
    );
  }

  getGameById(id: string): Observable<IResponse<{ game: NewGame }>> {
    return this._getSocketResponse<IResponse<{ game: NewGame }>>(
      `socket.v1.game.id`,
      { id }
    );
  }

  startGameById(id: string): Observable<IResponse<{ game: NewGame }>> {
    return this._getSocketResponse<IResponse<{ game: NewGame }>>(
      `socket.v1.game.start`,
      { id }
    );
  }

  getGameScore(
    id: string
  ): Observable<
    IResponse<{
      totalQuestion: number;
      correct: number;
      avgTime: number;
      score: number;
    }>
  > {
    return this._getSocketResponse<
      IResponse<{
        totalQuestion: number;
        correct: number;
        avgTime: number;
        score: number;
      }>
    >(`socket.v1.game.score`, { id });
  }

  updateCategory(
    id: string,
    categoryList: string[]
  ): Observable<IResponse<{ game: NewGame }>> {
    return this._getSocketResponse<IResponse<{ game: NewGame }>>(
      `socket.v1.game.update-category`,
      { id, categoryList }
    );
  }

  getSchoolList(): Observable<IResponse<{ schoolList: any[] }>> {
    return this._getSocketResponse<IResponse<{ schoolList: any[] }>>(
      `socket.v1.school.list`,
      {}
    );
  }

  updateInviteStatus(
    id: string,
    inviteId: string,
    status: string
  ): Observable<IResponse<{ game: NewGame }>> {
    return this._getSocketResponse<IResponse<{ game: NewGame }>>(
      `socket.v1.game.update-invite`,
      { id, inviteId, status }
    );
  }

  sendGameMailInvite(
    email: string,
    displayName: string,
    returnUrl: string
  ): Observable<IResponse<boolean>> {
    return this._getSocketResponse<IResponse<boolean>>(
      `socket.v1.game.invite-mail`,
      { email, displayName, returnUrl }
    );
  }

  getQuestionById(
    gameId: string,
    questionId: string
  ): Observable<IResponse<{ question: Question; answer?: GameAnswer }>> {
    return this._getSocketResponse<
      IResponse<{ question: Question; answer?: GameAnswer }>
    >(`socket.v1.question.id`, { id: questionId, gameId });
  }

  submitAnswer(
    gameId: string,
    questionId: string,
    answerId: string,
    seconds: number
  ): Observable<IResponse<{ answer: GameAnswer }>> {
    return this._getSocketResponse<IResponse<{ answer: GameAnswer }>>(
      `socket.v1.game-answer.add`,
      { questionId, gameId, answerId, seconds }
    );
  }

  getGameSelectedCategory(id: string): Observable<
    IResponse<{
      categoryList: CategoryWithQuestion[];
      game: NewGame;
    }>
  > {
    return this._getSocketResponse<
      IResponse<{
        categoryList: CategoryWithQuestion[];
        game: NewGame;
      }>
    >(`socket.v1.game.selected-category`, { id });
  }

  selectGameQuestion(
    gameId: string,
    questionId: string
  ): Observable<IResponse<boolean>> {
    return this._getSocketResponse<IResponse<boolean>>(
      `socket.v1.game.select-question`,
      { gameId, questionId }
    );
  }

  getQuestionCategory(): Observable<
    IResponse<{ questionCategoryList: QuestionCategory[] }>
  > {
    return this._getSocketResponse<
      IResponse<{ questionCategoryList: QuestionCategory[] }>
    >(`socket.v1.question-category.list`, {});
  }

  private _getSocketResponse<T>(path: string, query: any): Observable<T> {
    if (!this._socket) throw "Read event before the initialization";
    return new Observable<T>((observer) => {
      this._socket.emit(path, query);
      this._socket.on(`${path}.res`, (res: T) => {
        if ((res as IResponse<{}>).success) {
          observer.next(res);
        } else {
          observer.error((res as IResponse<{}>).error);
        }
        observer.complete();
      });
    });
  }

  resetSetting(): void {
    this._configSetting$.next(INITIAL_GAME_SETTING);
  }

  getSetting(): GameSetting {
    return this._configSetting$.value;
  }

  updateGameSetting(data: Partial<GameSetting>): void {
    const setting = this._configSetting$.value;
    this._configSetting$.next({ ...setting, ...data });
  }
}
