<div class="container space-2-bottom space-3-bottom--lg space-1-top--md space-1-top spinner-outter">
    <h4 class="mb-3 text-center">Account Details</h4>

    <div class="card-sm-columns card-sm-1-count card-lg-1-count">

        <dead-user-info [user]="user" [isAdmin]="false"
            (userUpdateEvent)="updateUser($event)"
            (updateGradDateEvent)="updateGradDate($event)">
        </dead-user-info>

        <dead-school-info [schoolId]="user?.schoolId" [isAdmin]="false"></dead-school-info>

        <dead-user-purchase-history></dead-user-purchase-history>
    </div>
</div>
