import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserService } from '../core/services/user.service';
import { loginStarted } from '../msal/actions/msal-login.actions';
import { MsalState } from '../msal/reducers/msal.reducers';

@Component({
    selector: 'dead-session-timeout',
    templateUrl: './session-timeout.component.html',
    styleUrls: ['./session-timeout.component.scss']
})
export class SessionTimeoutComponent implements OnInit {
    redirectUrl: string;
    constructor(
        private activatedRoute: ActivatedRoute,
        private msalStore: Store<MsalState>
    ) { }

    ngOnInit(): void {
        window.sessionStorage.clear();
        this.redirectUrl = this.activatedRoute.snapshot.queryParams.redirectTo ||
            this.activatedRoute.snapshot.queryParams.redirectto;
    }

    login() {
        this.msalStore.dispatch(loginStarted({ redirectUrl: this.redirectUrl }));
    }

}
