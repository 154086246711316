<div class="modal-header">
    <h4 class="modal-title">Update Users Expected Grad Date</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form class="js-validate js-step-form mx-lg-auto" novalidate="novalidate">
        <div class="form-group" [formGroup]="dateForm">
            <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Please Enter A Date" #dp="bsDatepicker" bsDatepicker triggers="none"
                    formControlName="graduationDate" [minDate]="minDate"
                    [bsConfig]="{ showClearButton: true, clearPosition: 'right', isAnimated: true, showWeekNumbers: false }"
                    [ngClass]="{'is-invalid': dateForm.get('graduationDate').touched  && dateForm.get('graduationDate').invalid, 'is-valid': dateForm.get('graduationDate').touched && dateForm.get('graduationDate').valid}">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary" (click)="dp.toggle()" type="button" [attr.aria-expanded]="dp.isOpen">
                        <fa-icon [icon]="faCalendar"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="saveChanges()">Save</button>
</div>
