import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { from, Subscription } from 'rxjs';
import { getSchoolsFromState, SchoolState } from 'src/app/reducers/school.reducers';
import { School } from 'src/app/core/models/school.model';
import { UpdateSchoolModalComponent } from '../user-info/update-school-modal/update-school-modal.component';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'dead-school-info',
    templateUrl: './school-info.component.html',
    styleUrls: ['./school-info.component.scss']
})
export class SchoolInfoComponent implements OnChanges, OnInit {
    @Input() schoolId: string;
    @Input() isAdmin: boolean = false;
    @Output() updateSchoolEvent = new EventEmitter<string>();

    school: School;
    faEdit = faEdit;

    private schools: School[];

    private readonly subscriptions = new Subscription();

    constructor(private modalService: NgbModal, private schoolStore: Store<SchoolState>,) { }

    ngOnInit(): void {
        this.subscriptions.add(
            this.schoolStore.select(getSchoolsFromState)
                .subscribe(schools => {
                    this.schools = schools;
                    this.school = this.schools.find(p => p.id === this.schoolId);
                })
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes?.schoolId?.currentValue) {
            this.school = null;
        }

        if (changes?.schoolId?.currentValue && this.schools) {
            this.school = this.schools.find(p => p.id === changes.schoolId.currentValue);
        }
    }

    updateSchool() {
        const modalRef = this.modalService.open(UpdateSchoolModalComponent, { size: 'md' });
        from(modalRef.result)
            .subscribe((newSchoolId: string) => {
                this.updateSchoolEvent.emit(newSchoolId);
            });
        modalRef.componentInstance.schoolId = cloneDeep(this.schoolId);
    }

}
