import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'dead-nbe-tutoring-sessions-science',
  templateUrl: './nbe-tutoring-sessions-science.component.html',
  styleUrls: ['./nbe-tutoring-sessions-science.component.scss']
})
export class NbeTutoringSessionsScienceComponent implements OnInit {

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
    ) { }

    ngOnInit(): void {
        const script = this.renderer.createElement('script');
        script.src = 'https://assets.calendly.com/assets/external/widget.js'
        this.elementRef.nativeElement.append(script);
    }

}
