<div>
    <div class="gradient-half-primary-v1">
        <div class="bg-img-hero"
            style="background-image: url(../../assets/img/bg/bg2.png);">
            <div class="container space-2">
                <div class="w-md-75 mx-md-auto">
                    <!-- Title -->
                    <div class="row align-items-sm-center mb-4">
                        <div class="col-sm-6 mb-3 mb-sm-0">
                            <h1 class="text-white mb-0">Help center</h1>
                        </div>
                        <div class="col-sm-6 text-sm-right mb-3 mb-sm-0">
                            <a class="d-block text-white"
                                href="https://www.facebook.com/DEADDrills"
                                target="_blank"> Community Forum <fa-icon [icon]="faExternalLink"></fa-icon>
                            </a>
                        </div>
                    </div>
                    <!-- End Title -->
                </div>
                <div class="w-md-75 mx-md-auto">
                    <!-- Input -->
                    <div class="js-focus-state input-group input-group-lg form form--lg mb-3">
                        <input type="text"
                            class="form-control form__input"
                            name="text"
                            required
                            placeholder="Search for answers"
                            aria-label="Search for answers"
                            [(ngModel)]="faqFilter"
                            (ngModelChange)="debouncedFaqUpdate()">
                        <!-- <span class="input-group-append form__append">
                            <button type="submit"
                                class="btn btn-dark px-5">
                                <fa-icon [icon]="faSearch"></fa-icon>
                            </button>
                        </span> -->
                    </div>
                    <!-- End Input -->
                </div>
            </div>
        </div>
    </div>
    <!-- End Hero Section -->
    <!-- Common Questions Section -->
    <div class="container space-2">
        <div class="row ">
            <div class="col-12"
                *ngIf="isLoading === true">
                <ngb-progressbar [value]="100"
                    type="success"
                    [striped]="true"
                    [animated]="true"
                    height="20px">Loading...</ngb-progressbar>
            </div>
            <div class="col-lg-12"
                *ngIf="isLoading === false">
                <div *ngIf="filteredFaqs.length === 0">
                    <span>No Results Found</span>
                </div>
                <div *ngIf="filteredFaqs.length > 0">
                    <accordion [isAnimated]="true"
                        *ngFor="let faq of filteredFaqs; let i = index">
                        <accordion-group #myGroup>
                            <!-- Heading-->
                            <div class="text-primary"
                                accordion-heading>
                                <div class="row">
                                    <div class="col-10 col-md-11"
                                        [innerHtml]="faq.question | safeHtml"></div>
                                    <div class="col-2 col-md-1 align-self-center">
                                        <fa-icon *ngIf="!myGroup.isOpen"
                                            [icon]="faPlus"
                                            size="lg"></fa-icon>
                                        <fa-icon *ngIf="myGroup.isOpen"
                                            [icon]="faMinus"
                                            size="lg"></fa-icon>
                                    </div>
                                </div>
                            </div>
                            <!--Content-->
                            <div [innerHtml]="faq.answer | safeHtml"></div>
                        </accordion-group>
                    </accordion>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Common Questions Section -->
<!-- ========== END MAIN CONTENT ========== -->
