import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';

@Component({
    selector: 'dead-nbe-review-manual',
    templateUrl: './nbe-review-manual.component.html',
    styleUrls: ['./nbe-review-manual.component.scss']
})
export class NbeReviewManualComponent implements AfterViewInit {
    @ViewChild('nbepurchaseform') nbePurchase: ElementRef;
    private iframe: any;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
    ) { }

    ngAfterViewInit(): void {
        let f = this.renderer.createElement("iframe");
        f.src =
            "https://forms.zohopublic.com/sjohnson2/form/DaveNBEReviewManual2024/formperma/-AOxcaYWpuJJNJ0HfcPuB0diLZpHqs_RCWeMIyxPrQY";
        f.style.border = "none";
        f.style.height = "1600px";
        f.style.width = "100%";
        f.style.transition = "all 0.5s ease"; // No I18N
        f.id = "myIframe"
        f.addEventListener('load', () => {
            this.iframe = this.elementRef.nativeElement.querySelector("#myIframe");
        });
        window.addEventListener(
            'message',
            (event) => {
                if (this.iframe) {
                    let zf_ifrm_data = event.data.split("|");
                    let zf_perma = zf_ifrm_data[0];
                    let zf_ifrm_ht_nw =
                        parseInt(zf_ifrm_data[1], 10) + 15 + "px";

                    if (this.iframe.src.indexOf("formperma") > 0 && this.iframe.src.indexOf(zf_perma) > 0) {
                        let prevIframeHeight = this.iframe.style.height;
                        if (prevIframeHeight != zf_ifrm_ht_nw) {
                            this.iframe.style.height = zf_ifrm_ht_nw;
                        }
                    }
                }
            },
            false
        );
        this.nbePurchase.nativeElement.append(f);
    }

}
