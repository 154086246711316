import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'dead-update-subscription-modal',
    templateUrl: './update-subscription-modal.component.html',
    styleUrls: ['./update-subscription-modal.component.scss']
})
export class UpdateSubscriptionModalComponent implements OnInit {
    faCalendar = faCalendar;
    minDate = new Date();
    dateForm: UntypedFormGroup;
    isOpen = false;
    @Input() subEndDate: string;

    constructor(
        public activeModal: NgbActiveModal,
        private fb: UntypedFormBuilder,
    ) {
        this.minDate.setDate(this.minDate.getDate() - 7);
    }

    ngOnInit(): void {
        this.dateForm = this.fb.group({
            subEndDate: this.fb.control(this.subEndDate ? new Date(this.subEndDate) : null, Validators.required)
        });
    }

    saveChanges(): void {
        if (this.dateForm.valid) {
            this.activeModal.close(this.dateForm.value.subEndDate);
        }
    }
}
