import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PhoneNumberValidator } from '../core/validators/phone.validator';
import { Store } from '@ngrx/store';
import { EmailState, getEmailState } from '../reducers/email.reducers';
import { sendContactUsRequest } from '../actions/email.actions';

@Component({
    selector: 'dead-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
    form: UntypedFormGroup;

    constructor(
        private fb: UntypedFormBuilder,
        private emailStore: Store<EmailState>
    ) { }

    ngOnInit() {
        this.form = this.fb.group({
            name: this.fb.control('', Validators.required),
            email: this.fb.control('', [Validators.email, Validators.required]),
            phone: this.fb.control('', [Validators.required, PhoneNumberValidator()]),
            subject: this.fb.control('', [
                Validators.required,
                Validators.minLength(5)
            ]),
            message: this.fb.control('', [
                Validators.required,
                Validators.minLength(5)
            ])
        });

        this.emailStore.select(getEmailState)
            .subscribe(emailState => {
                if (emailState.sendContactUsComplete) {
                    this.form.reset();
                }
            });
    }

    onSubmit() {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const contactUsRequest = {
                name: this.form.get('name').value,
                email: this.form.get('email').value,
                phone: this.form.get('phone').value,
                message: this.form.get('message').value,
                subject: this.form.get('subject').value
            };
            this.emailStore.dispatch(sendContactUsRequest({ contactUsRequest }));
        }
    }
}
