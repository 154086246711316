import { Component, OnInit } from '@angular/core';
@Component({
    selector: 'dead-admin',
    templateUrl: './admin.component.html'
})
export class AdminComponent implements OnInit {
    links = [
        { title: 'Questions', route: '/admin/questions' },
        { title: 'Question Errors', route: '/admin/questions/errors' },
        { title: 'Question Categories', route: '/admin/questions/categories' },
        { title: 'Schools', route: '/admin/schools' },
        { title: 'Email Templates', route: '/admin/email-templates' },
        { title: 'Email', route: '/admin/email' },
        { title: 'Purchase History', route: '/admin/purchases' },
        { title: 'Voucher Codes', route: '/admin/vouchers' },
        { title: 'Users', route: '/admin/users' },
        { title: 'FAQs', route: '/admin/faq' },
        { title: 'Suggested Questions', route: '/admin/suggested-questions' }
    ];
    activeRoute;
    constructor() { }
    ngOnInit() {
        this.activeRoute = window.location.pathname;
    }
}
