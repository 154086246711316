import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    CanActivate,
    Router
} from '@angular/router';
import { Injectable } from '@angular/core';
import { DEADMsalService } from 'src/app/msal/services/dead-msal.service';
@Injectable({
    providedIn: 'root'
})
export class LoggedInGuardService implements CanActivate {
    constructor(private deadMsalService: DEADMsalService, private router: Router) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | UrlTree {
        return this.deadMsalService.isLoggedIn() ? true : this.router.parseUrl(`/sign-in-sign-up?redirect=${state.url}`);
    }
}
