

<div class="card card-frame card-primary-frame mt-2">
    <!-- Header -->
    <div class="card-header text-center p-0">
        <h4 class="h6 text-primary mb-2 mt-2">
            User Info
            <fa-icon *ngIf="!isEditing" [icon]="faEdit" style="cursor: pointer;" class="pr-1 pl-1" (click)="editUser()">
            </fa-icon>
        </h4>
    </div>
    <!-- End Header -->
    <div class="card-body">
        <div *ngIf="user; else loadingUserDetails">

            <!-- User Details View -->
            <div *ngIf="!isEditing">
                <div class="form-group row">
                    <label class="col-sm-2 col-form-label pr-0"><b>Name:</b></label>
                    <div class="col-sm-4">
                        <span class="form-control-plaintext">{{user.firstName + ' ' + user.lastName}}</span>
                    </div>

                    <label class="col-sm-2 col-form-label bold pr-0"><b>Email:</b></label>
                    <div class="col-sm-4">
                        <a href="mailto:{{user.email}}" class="form-control-plaintext">{{user.email}}</a>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 col-form-label pr-0"><b>Address:</b></label>
                    <div class="col-sm-4">
                        <span class="form-control-plaintext">{{user.streetAddress}}</span>
                    </div>

                    <label class="col-sm-2 col-form-label bold pr-0"><b>City:</b></label>
                    <div class="col-sm-4">
                        <span class="form-control-plaintext">{{user.city}}</span>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 col-form-label pr-0"><b>State:</b></label>
                    <div class="col-sm-4">
                        <span class="form-control-plaintext">{{user.state}}</span>
                    </div>

                    <label class="col-sm-2 col-form-label bold pr-0"><b>Zip Code:</b></label>
                    <div class="col-sm-4">
                        <span class="form-control-plaintext">{{user.postalCode}}</span>
                    </div>
                </div>
                <div class="form-group row" *ngIf="user.groups.includes('Student') || user.groups.includes('Instructor')">
                    <label class="col-sm-2 col-form-label pr-0"><b>Sub End Date:</b></label>
                    <div class="col-sm-4">
                        <button *ngIf="isAdmin" (click)="updateSubcription()" class="form-control-plaintext btn btn-link text-left pl-0 pr-0">
                            <span *ngIf="user.subscriptionEndDate">{{user.subscriptionEndDate | date}}</span>
                            <span *ngIf="!user.subscriptionEndDate">Click to Set</span>
                        </button>
                        <span *ngIf="!isAdmin" class="form-control-plaintext">{{user.subscriptionEndDate | date}}</span>
                    </div>

                    <label class="col-sm-2 col-form-label bold pr-0"><b>Grad Date:</b></label>
                    <div class="col-sm-4">
                        <button (click)="updateGradDate()" class="form-control-plaintext btn btn-link text-left pl-0 pr-0">
                            <span *ngIf="user.graduationDate">{{user.graduationDate | date}}</span>
                            <span *ngIf="!user.graduationDate">Click to Set</span>
                        </button>
                    </div>

                    <div class="col-sm-6" *ngIf="isStudent">
                        <button (click)="clearStudentTestData()" class="btn btn-xs btn-danger">
                            Clear Test Data
                        </button>
                    </div>
                </div>
            </div>
            <!-- End User Details View -->

            <!-- User Details Edit Form -->
            <div *ngIf="isEditing">
                <form [formGroup]="userDetailsForm" (ngSubmit)="updateUser()">
                    <div class="form-row">
                        <div class="form-group col-md-6">
                        <label class="h6 small d-block text-uppercase" for="firstName">First Name</label>
                        <input type="text" class="form-control" id="firstName" placeholder="First Name" formControlName="firstName"
                            [ngClass]="{'is-invalid': userDetailsForm.get('firstName').touched  && userDetailsForm.get('firstName').invalid, 'is-valid': userDetailsForm.get('firstName').touched && userDetailsForm.get('firstName').valid}"/>
                        </div>
                        <div class="form-group col-md-6">
                        <label class="h6 small d-block text-uppercase" for="lastName">Last Name</label>
                        <input type="text" class="form-control" id="lastName" placeholder="Last Name" formControlName="lastName"
                            [ngClass]="{'is-invalid': userDetailsForm.get('lastName').touched  && userDetailsForm.get('lastName').invalid, 'is-valid': userDetailsForm.get('lastName').touched && userDetailsForm.get('lastName').valid}"/>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label class="h6 small d-block text-uppercase" for="streetAddress">Address</label>
                            <input type="text" class="form-control" id="streetAddress" placeholder="Address" formControlName="streetAddress"
                                [ngClass]="{'is-invalid': userDetailsForm.get('streetAddress').touched  && userDetailsForm.get('streetAddress').invalid, 'is-valid': userDetailsForm.get('streetAddress').touched && userDetailsForm.get('streetAddress').valid}"/>
                        </div>
                        <div class="form-group col-md-6">
                            <label class="h6 small d-block text-uppercase" for="city">City</label>
                            <input type="text" class="form-control" id="city" placeholder="City" formControlName="city"
                                [ngClass]="{'is-invalid': userDetailsForm.get('city').touched  && userDetailsForm.get('city').invalid, 'is-valid': userDetailsForm.get('city').touched && userDetailsForm.get('city').valid}"/>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label class="h6 small d-block text-uppercase" for="state">State</label>
                            <select formControlName="state" id="questionCatId" class="form-control"
                                [ngClass]="{'is-invalid': userDetailsForm.get('state').touched  && userDetailsForm.get('state').invalid, 'is-valid': userDetailsForm.get('state').touched && userDetailsForm.get('state').valid}">
                                <option *ngFor="let state of states" [ngValue]="state.abbreviation">
                                    {{ state.name }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-md-6">
                            <label class="h6 small d-block text-uppercase" for="postalCode">Zip Code</label>
                            <input type="text" class="form-control" id="postalCode" placeholder="Zip Code" formControlName="postalCode"
                                [ngClass]="{'is-invalid': userDetailsForm.get('postalCode').touched  && userDetailsForm.get('postalCode').invalid, 'is-valid': userDetailsForm.get('postalCode').touched && userDetailsForm.get('postalCode').valid}"/>
                        </div>
                    </div>
                    <div class="btn-group">
                        <button type="submit" class="btn btn-primary">Submit</button>
                        <button type="button" class="btn btn-danger" (click)="isEditing = false">Cancel</button>
                    </div>
                </form>
            </div>
            <!-- End User Details Edit Form -->

        </div>
        <ng-template #loadingUserDetails>
            <ngb-progressbar [value]="100" type="success" [striped]="true" [animated]="true" height="20px">Loading...</ngb-progressbar>
        </ng-template>
    </div>
</div>
