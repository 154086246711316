<div class="modal-header">
    <h4 class="modal-title">Update Users School</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="updateSchoolForm" class="js-validate js-step-form mx-lg-auto" novalidate="novalidate">
        <div class="form-group">
            <label class="h6 small d-block text-uppercase" for="schoolId">School</label>
            <select *ngIf="schools$ | async as schools; else loading" id="schoolId" class="form-control" formControlName="schoolId"
                (change)="selectedSchool = $event.target.value">
                <option value="">Please Select A School</option>
                <option *ngFor="let school of schools" [value]="school.id">{{school.name}}</option>
            </select>
            <ng-template #loading>
                <ngb-progressbar [value]="100" type="success" [striped]="true" [animated]="true" height="20px">Loading...</ngb-progressbar>
            </ng-template>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="activeModal.close(updateSchoolForm.controls.schoolId.value)">Save</button>
</div>
