import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { orderBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getSchoolsFromState, SchoolState } from 'src/app/reducers/school.reducers';
import { School } from 'src/app/core/models/school.model';

@Component({
    selector: 'dead-update-school-modal',
    templateUrl: './update-school-modal.component.html',
    styleUrls: ['./update-school-modal.component.scss']
})
export class UpdateSchoolModalComponent implements OnInit {
    @Input() schoolId: string;
    schools$: Observable<School[]>;
    selectedSchool;
    updateSchoolForm: UntypedFormGroup;

    constructor(
        public activeModal: NgbActiveModal,
        private schoolStore: Store<SchoolState>,
        private fb: UntypedFormBuilder,
    ) { }

    ngOnInit(): void {
        this.updateSchoolForm = this.fb.group({
            schoolId: this.fb.control(this.schoolId)
        });
        this.schools$ = this.schoolStore.select(getSchoolsFromState)
            .pipe(map((schools: School[]) => orderBy(schools, p => p.name)));
    }
}
