<div class="bg-gray-100">
    <div class="d-flex align-items-center height-90vh">
        <div class="container text-center">
            <div class="w-lg-60 mx-lg-auto">
                <h1 class="display-3 font-size-48--md-down font-weight-medium mb-3">Session Timeout</h1>
                <img class="position-relative bg-primary" style="width: 100%; height: auto;"
                    src="../../assets/img/401-error.jpg">
                <!-- Title -->
                <p class="lead mb-0">Oops! Looks like your session has timed out.</p>
                <p class="lead mb-0">Click <button type="button" class="btn btn-text btn-text-primary p-0"
                        (click)="login()">HERE</button> to login.</p>
                <!-- End Title -->
            </div>
        </div>
    </div>
</div>
