<div class="container pt-5">
    <h1 class="display-4">NBE Review Webinar Sign-Up</h1>
    <p>
        This 4-Day intensive NBE Review covers all major subjects testable on the National Board Exam.
        Two days will be devoted to Arts and two days will be devoted to Sciences.
        All registrations include 3-months unlimited access to the D.E.A.D. Exam Preparation Simulator.
        (D.E.A.D. Access is provided via voucher at the conclusion of the Webinar Series).
    </p>
    <p>
        We rely heavily on <a [routerLink]="'/nbe-review-manual'">Taggart’s Review Manual</a> during this series.
        It is strongly recommended that you purchase a copy if you do not already own one.
        If you would like a copy, you may add it at checkout.
    </p>
    <div #nbewebinarsignup></div>
</div>
