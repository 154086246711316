import { Component } from '@angular/core';

@Component({
    selector: 'dead-nbe-tutoring-sessions-signup',
    templateUrl: './nbe-tutoring-sessions-signup.component.html',
    styleUrls: ['./nbe-tutoring-sessions-signup.component.scss']
})
export class NbeTutoringSessionsSignupComponent {

    constructor() { }

}
