import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from "@angular/core";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { cloneDeep } from "lodash";
import { firstValueFrom, from } from "rxjs";
import { States } from "src/app/core/constants";
import { User } from "src/app/core/models/user.model";
import { TestingService } from "src/app/core/services/testing.service";
import { ToastService } from "src/app/core/services/toast.service";
import { YesNoModalComponent } from "../yes-no-modal/yes-no-modal.component";
import { UpdateGradDateModalComponent } from "./update-grad-date-modal/update-grad-date-modal.component";
import { UpdateSubscriptionModalComponent } from "./update-subscription-modal/update-subscription-modal.component";

@Component({
    selector: "dead-user-info",
    templateUrl: "./user-info.component.html",
    styleUrls: ["./user-info.component.scss"],
})
export class UserInfoComponent implements OnChanges {
    isEditing = false;
    faEdit = faEdit;
    userDetailsForm: UntypedFormGroup;
    states = States;
    isStudent = false;

    @Input() user: User;
    @Input() isAdmin: boolean;
    @Output() userUpdateEvent = new EventEmitter<User>();
    @Output() updateSubcriptionEvent = new EventEmitter<User>();
    @Output() updateGradDateEvent = new EventEmitter<User>();

    constructor(
        private fb: UntypedFormBuilder,
        private modalService: NgbModal,
        private testingService: TestingService,
        private toastService: ToastService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.user.currentValue) {
            this.user = changes.user.currentValue;
            this.isEditing = false;
            this.isStudent = this.user.groups.some((p) => p === "Student");
        }
    }

    async clearStudentTestData(): Promise<void> {
        const modalRef = this.modalService.open(YesNoModalComponent, {
            size: "md",
        });
        modalRef.componentInstance.header = "Delete Test Data";
        modalRef.componentInstance.body =
            "Are you sure you want to delete these tests?";
        modalRef.componentInstance.continueBtnTxt = "Delete Tests";
        modalRef.componentInstance.cancelBtnTxt = "Cancel";

        const modalResult = await modalRef.result
            .then((_) => true)
            .catch((_) => false);

        if (modalResult) {
            await firstValueFrom(
                this.testingService.clearTestData(this.user.userId)
            ).catch((_) => this.toastService.error("Error Deleting Tests"));

            this.toastService.success("Tests Deleted Successfully!");
        }
    }

    editUser() {
        this.userDetailsForm = this.fb.group({
            firstName: this.fb.control(
                this.user?.firstName,
                Validators.required
            ),
            lastName: this.fb.control(this.user?.lastName, Validators.required),
            streetAddress: this.fb.control(
                this.user?.streetAddress,
                Validators.required
            ),
            city: this.fb.control(this.user?.city, Validators.required),
            postalCode: this.fb.control(
                this.user?.postalCode,
                Validators.required
            ),
            state: this.fb.control(this.user?.state, Validators.required),
        });

        this.isEditing = true;
    }

    updateUser() {
        if (this.userDetailsForm.valid) {
            const userToUpdate = cloneDeep(this.user);
            userToUpdate.firstName = this.userDetailsForm.value.firstName;
            userToUpdate.lastName = this.userDetailsForm.value.lastName;
            userToUpdate.streetAddress =
                this.userDetailsForm.value.streetAddress;
            userToUpdate.city = this.userDetailsForm.value.city;
            userToUpdate.postalCode = this.userDetailsForm.value.postalCode;
            userToUpdate.state = this.userDetailsForm.value.state;
            this.userUpdateEvent.emit(userToUpdate);
            this.isEditing = false;
        }
    }

    updateSubcription(): void {
        const modalRef = this.modalService.open(
            UpdateSubscriptionModalComponent,
            { size: "md" }
        );
        from(modalRef.result).subscribe((result: Date) => {
            const userToUpdate = cloneDeep(this.user);
            userToUpdate.subscriptionEndDate = result;
            this.updateSubcriptionEvent.emit(userToUpdate);
        });
        modalRef.componentInstance.subEndDate = this.user.subscriptionEndDate;
    }

    updateGradDate(): void {
        const modalRef = this.modalService.open(UpdateGradDateModalComponent, {
            size: "md",
        });
        from(modalRef.result).subscribe((result: Date) => {
            const userToUpdate = cloneDeep(this.user);
            userToUpdate.graduationDate = result;
            this.updateGradDateEvent.emit(userToUpdate);
        });
        modalRef.componentInstance.graduationDate = this.user.graduationDate;
    }
}
