<!-- Hero Section -->
<div class="container gradient-overlay-half-dark-v2 bg-img-hero mb-3"
  style="background-image: url(../../assets/img/1920x800/img3.jpg);">
  <div class="container space-2 space-4-top--lg space-3-bottom--lg">
    <div class="w-lg-60 text-center mx-lg-auto">
      <h1 class="display-2 font-size-48--md-down text-white mb-0">Contact Us</h1>
    </div>
  </div>
</div>
<!-- End Hero Section -->
<!-- Contact Us Form Section -->
<div class="container">
  <!-- Title -->
  <div class="w-md-80 w-lg-60 text-center mx-md-auto mb-9">
    <span class="u-label u-label--sm u-label--purple mb-3">Leave a Message</span>
    <h2 class="h3">Tell us about yourself</h2>
    <p>Whether you have questions or you would just like to say hello, contact us.</p>
  </div>
  <!-- End Title -->

  <div class="w-lg-80 mx-auto">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="row">
        <!-- Input -->
        <div class="col-sm-6 mb-6">
          <div class="input-group form">
            <input class="form-control form__input" type="text" name="name" placeholder="Your name"
              aria-label="Your name" formControlName="name"
              [ngClass]="{'is-invalid': form.get('name').touched  && form.get('name').invalid, 'is-valid': form.get('name').touched && form.get('name').valid}">
          </div>
          <div *ngIf="form.get('name').invalid && form.get('name').touched" class="invalid-feedback"
            style="display: block;">
            Please enter your name.
          </div>
        </div>
        <!-- End Input -->

        <!-- Input -->
        <div class="col-sm-6 mb-6">
          <div class="input-group form">
            <input class="form-control form__input" type="email" name="email" placeholder="Your email address"
              formControlName="email"
              [ngClass]="{'is-invalid': form.get('email').touched  && form.get('email').invalid, 'is-valid': form.get('email').touched && form.get('email').valid}">
          </div>
          <div *ngIf="form.get('email').touched && form.get('email').errors?.email" class="invalid-feedback"
            style="display: block;">
            Please enter a valid email address.
          </div>
          <div *ngIf="form.get('email').touched && form.get('email').errors?.required" class="invalid-feedback"
            style="display: block;">
            Please enter your email address.
          </div>
        </div>
        <!-- End Input -->

        <div class="w-100"></div>

        <!-- Input -->
        <div class="col-sm-6 mb-6">
          <div class="input-group form">
            <input class="form-control form__input" type="text" name="subject" placeholder="Subject"
              aria-label="Subject" formControlName="subject"
              [ngClass]="{'is-invalid': form.get('subject').touched  && form.get('subject').invalid, 'is-valid': form.get('subject').touched && form.get('subject').valid}">
          </div>
          <div *ngIf="form.get('subject').touched && form.get('subject').errors?.required" class="invalid-feedback"
            style="display: block;">
            Please enter a subject.
          </div>
          <div *ngIf="form.get('phone').touched && form.get('subject').errors?.minlength" class="invalid-feedback"
            style="display: block;">
            Subject must be a minimum of 5 characters.
          </div>
        </div>
        <!-- End Input -->

        <!-- Input -->
        <div class="col-sm-6 mb-6">
          <div class="input-group form">
            <input class="form-control form__input" type="text" name="phone" placeholder="Your phone number"
              aria-label="Your phone number" formControlName="phone"
              [ngClass]="{'is-invalid': form.get('phone').touched  && form.get('phone').invalid, 'is-valid': form.get('phone').touched && form.get('phone').valid}">
          </div>
          <div *ngIf="form.get('phone').touched && form.get('phone').errors?.phone" class="invalid-feedback"
            style="display: block;">
            Please enter a valid phone number.
          </div>
        </div>
        <!-- End Input -->
      </div>

      <!-- Input -->
      <div class="mb-9">
        <div class="input-group form">
          <textarea class="form-control form__input" rows="6" name="text" placeholder="How can we help you?"
            aria-label="How can we help you?" formControlName="message"
            [ngClass]="{'is-invalid': form.get('message').touched  && form.get('message').invalid, 'is-valid': form.get('message').touched && form.get('message').valid}"></textarea>
        </div>
        <div *ngIf="form.get('message').touched && form.get('message').errors?.required" class="invalid-feedback"
          style="display: block;">
          Please enter a message.
        </div>
        <div *ngIf="form.get('message').touched && form.get('message').errors?.minlength" class="invalid-feedback"
          style="display: block;">
          Your message must be atleast 5 characters.
        </div>
      </div>
      <!-- End Input -->

      <div class="text-center">
        <button type="submit" class="btn btn-primary btn-wide mb-4">Submit</button>
        <p class="small">We'll get back to you in 1-2 business days.</p>
      </div>
    </form>
  </div>
</div>
<!-- End Contact Us Form Section -->

<!-- <form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <input [ngClass]="{'is-invalid': form.get('name').invalid && form.get('name').touched}" type="text"
      class="form-control" placeholder="Name" formControlName="name">
    <div *ngIf="form.get('name').invalid && form.get('name').touched" class="invalid-feedback">
      Please enter your name.
    </div>
  </div>
  <div class="form-group">
    <input [ngClass]="{'is-invalid': form.get('email').invalid && form.get('email').touched}" type="email"
      class="form-control" placeholder="Email" formControlName="email">
    <div *ngIf="form.get('email').touched && form.get('email').errors.required" class="invalid-feedback">
      Please enter your email address.
    </div>
    <div *ngIf="form.get('email').touched && form.get('email').errors.email" class="invalid-feedback">
      Please enter your email address.
    </div>
  </div>
  <div class="form-group">
    <input [ngClass]="{'is-invalid': form.get('phone').invalid && form.get('phone').touched}" type="text"
      class="form-control" placeholder="Phone" formControlName="phone">
    <div *ngIf="form.get('phone').touched && form.get('phone').errors.required" class="invalid-feedback">
      Please enter your phone number.
    </div>
    <div *ngIf="form.get('phone').touched && form.get('phone').errors.minLength" class="invalid-feedback">
      Please enter a valid phone number.
    </div>
  </div>
  <div class="form-group">
    <textarea [ngClass]="{'is-invalid': form.get('message').invalid && form.get('message').touched}"
      class="form-control" rows="3" placeholder="Message" formControlName="message"></textarea>
    <div *ngIf="form.get('message').touched && form.get('message').errors.required" class="invalid-feedback">
      Please enter your message.
    </div>
    <div *ngIf="form.get('message').touched && form.get('message').errors.minLength" class="invalid-feedback">
      Your message must be longer than 10 characters.
    </div>
  </div>
  <button type="submit" class="btn btn-secondary">Submit</button>
</form>
 -->
