<div class="card card-frame card-primary-frame mt-2">
    <!-- Header -->
    <div class="card-header text-center p-0">
        <h4 class="h6 text-primary mb-2 mt-2">
            School
            <fa-icon *ngIf="isAdmin" [icon]="faEdit" style="cursor: pointer;" class="pr-1 pl-1" (click)="updateSchool()">
            </fa-icon>
        </h4>
    </div>
    <!-- End Header -->
    <div class="card-body">
        <ng-template #loadingSchoolDetails>
            <ngb-progressbar [value]="100" type="success" [striped]="true" [animated]="true" height="20px">Loading...</ngb-progressbar>
        </ng-template>
        <div *ngIf="school; else loadingSchoolDetails">
            <div class="form-group row">
                <label class="col-sm-2 col-form-label pr-0"><b>Name:</b></label>
                <div class="col-sm-4">
                    <span class="form-control-plaintext">{{school.name}}</span>
                </div>

                <label class="col-sm-2 col-form-label bold pr-0"><b>Address:</b></label>
                <div class="col-sm-4">
                    <span class="form-control-plaintext">{{school.address1}}</span>
                </div>
            </div>
            <div *ngIf="isAdmin" class="form-group row">
                <label class="col-sm-2 col-form-label pr-0"><b>Contact:</b></label>
                <div class="col-sm-4">
                    <span class="form-control-plaintext">{{school.contacts[0].name}}</span>
                </div>

                <label class="col-sm-2 col-form-label bold pr-0"><b>Email:</b></label>
                <div class="col-sm-4">
                    <a href="mailto:{{school.contacts[0].email}}"
                        class="form-control-plaintext btn btn-link text-left pl-0 pr-0">{{school.contacts[0].email}}</a>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 col-form-label pr-0"><b>Website:</b></label>
                <div class="col-sm-10">
                    <a [href]="school.website.includes('http') ? school.website : 'https://{{school.website}}'"
                            class="form-control-plaintext btn btn-link text-left pl-0 pr-0" target="_blank">{{school.website}}</a>
                </div>
            </div>
        </div>
    </div>
</div>
