import { Component, OnInit, HostListener, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import {
    faUserCircle,
    faUser,
    faInfoCircle,
    faAngleDown,
    faColumns,
    faUsers,
    faEnvelopeOpenText,
    faArrowUp
} from '@fortawesome/free-solid-svg-icons';
import {
    faQuestionCircle
} from '@fortawesome/free-regular-svg-icons';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { getMsalState, MsalState } from 'src/app/msal/reducers/msal.reducers';
import { loginStarted } from 'src/app/msal/actions/msal-login.actions';
import { logoutStarted } from 'src/app/msal/actions/msal-logout.actions';

@Component({
    selector: 'dead-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
    faUserCircle = faUserCircle;
    faUser = faUser;
    faInfoCircle = faInfoCircle;
    faAngleDown = faAngleDown;
    faQuestionCircle = faQuestionCircle;
    faColumns = faColumns;
    faUsers = faUsers;
    faArrowUp = faArrowUp;
    faEnvelopeOpenText = faEnvelopeOpenText;
    userSchoolId: string;
    isInstructor: boolean;
    isStudent: boolean;
    isAdmin: boolean;
    isLoggedIn: boolean;
    isUser: boolean;

    private readonly subscriptions = new Subscription();

    constructor(
        private msalStore: Store<MsalState>,
        private _eref: ElementRef,
    ) { }

    ngOnInit(): void {
        this.subscriptions.add(
            this.msalStore.select(getMsalState)
                .subscribe(p => this.loadUserParams(p))
        );
    }

    private loadUserParams(msalState: MsalState = null) {
        this.userSchoolId = msalState.schoolId;
        this.isInstructor = msalState.isInstructor;
        this.isAdmin = msalState.isAdmin;
        this.isStudent = msalState.isStudent;
        this.isLoggedIn = msalState.isLoggedIn;
        this.isUser = msalState.isLoggedIn;
    }

    ngAfterViewInit(): void {
        jQuery('.js-mega-menu')['HSMegaMenu']({
            event: 'hover',
            pageContainer: jQuery('.container'),
            breakpoint: 767,
            hideTimeOut: 0
        });

        // initialization of header
        jQuery['HSCore'].components.HSHeader.init(jQuery('#header'));
    }

    @HostListener('document:click', ['$event']) onClick(event) {
        if (!this._eref.nativeElement.contains(event.target)) {
            this.closeNav();
        }
    }

    login(): void {
        this.msalStore.dispatch(loginStarted({ redirectUrl: '' }));
    }

    logout(): void {
        this.msalStore.dispatch(logoutStarted({ redirectUrl: '' }));
    }

    closeNav() {
        $('.navbar-collapse')['collapse']('hide');
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }
}
