import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { loadSchools } from 'src/app/actions/school.actions';
import { SchoolState } from 'src/app/reducers/school.reducers';

@Injectable({
    providedIn: 'root'
})
export class SchoolsResolver implements Resolve<any> {
    constructor(
        private schoolStore: Store<SchoolState>
    ) { }

    resolve() {
        this.schoolStore.dispatch(loadSchools())
        return;
    }
}
