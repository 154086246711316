import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ColumnMode, SortType } from '@swimlane/ngx-datatable';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SubscriptionsService } from 'src/app/core/services/subscriptions.service';
import { getPurchasesForCurrentUserFromState, isLoadingPurchases, PurchaseState } from 'src/app/reducers/purchase.reducers';
import { PurchaseSubscription } from '../purchaseSubscription';

@Component({
    selector: 'dead-user-purchase-history',
    templateUrl: './user-purchase-history.component.html',
    styleUrls: ['./user-purchase-history.component.scss']
})
export class UserPurchaseHistoryComponent implements OnInit {
    purchaseHistory$: Observable<PurchaseSubscription[]>;
    ColumnMode = ColumnMode;
    SortType = SortType;
    purchasesLoading = true;

    @Input() userId: string;

    private readonly subscriptions = new Subscription();

    constructor(
        private purchaseStore: Store<PurchaseState>,
        private subsciptionService: SubscriptionsService,
    ) { }

    ngOnInit(): void {
        if (!this.userId) {
            this.purchaseHistory$ = this.purchaseStore.select(getPurchasesForCurrentUserFromState);

            this.subscriptions.add(
                this.purchaseStore.select(isLoadingPurchases).subscribe(isLoading => this.purchasesLoading = isLoading)
            );
        } else {
            this.purchaseHistory$ = this.subsciptionService.getPurchasedSubscriptionsByUserId(this.userId).pipe(tap(() => this.purchasesLoading = false));
        }
    }

}
