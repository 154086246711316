<div class="container pt-5">
    <h1 class="display-4">NBE Tutoring Session Sign-Up</h1>
    <ul class="list-unstyled">
        <li>
            <p>
                1-on-1 style tutoring via Zoom with small groups of students (no more than 6 per session).
                All of our tutors are  mortuary science professors with years of experience.
            </p>
        </li>
        <li>
            <p>
                Tutoring Sessions cost $60 and are first come first serve.
            </p>
        </li>
        <li>
            <p>
                We strongly recommend that you have the <a [routerLink]="'/nbe-review-manual'">NBE Review Manual by Thomas Taggart, Ph.D</a> during review.
            </p>
        </li>
        <li>
            <p>
                If you are in need of one click <a [routerLink]="'/nbe-review-manual'">HERE</a> to purchase!
            </p>
        </li>
      </ul>
    <tabset type="pills">
        <tab heading="NBE Arts Session Signup">
            <dead-nbe-tutoring-sessions-arts></dead-nbe-tutoring-sessions-arts>
        </tab>
        <tab heading="NBE Science Session Signup">
            <dead-nbe-tutoring-sessions-science></dead-nbe-tutoring-sessions-science>
        </tab>
    </tabset>
</div>
