<div class="card card-frame card-primary-frame mt-2">
    <!-- Header -->
    <div class="card-header text-center p-0">
        <h4 class="h6 text-primary mb-2 mt-2">Purchase History</h4>
    </div>
    <!-- End Header -->
    <div class="card-body">
        <ngx-datatable headerHeight="50" class="material" [rows]="purchaseHistory$ | async" rowHeight="'auto'" [loadingIndicator]="purchasesLoading"
            [limit]="10" [columnMode]="ColumnMode.force" [footerHeight]="50" [sortType]="SortType.multi">

            <ngx-datatable-column name="Checkout Date" prop="purchaseDate">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <div>{{row.purchaseDate | date }}</div>
                </ng-template>
            </ngx-datatable-column>


            <ngx-datatable-column name="Type" prop="subscriptionTypeCode">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <div>{{row.subscriptionTypeCode }}</div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Voucher Code" prop="voucherCode">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <div>{{row.voucherCode }}</div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Paid" prop="isPaid">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <div>{{row.isPaid }}</div>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Licenses" prop="numberOfLicenses">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <div>{{row.numberOfLicenses }}</div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Price" [sortable]="true" prop="purchasePrice">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <div>{{row.purchasePrice | currency }}</div>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>
