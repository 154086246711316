import { Injectable, NgModule } from '@angular/core';
import { DefaultUrlSerializer, RouterModule, Routes, UrlSerializer, UrlTree } from '@angular/router';
import { AccountComponent } from './account/account.component';
import { AdminComponent } from './admin/admin.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FaqResolver } from './core/resolvers/faqs.resolve';
import { PurchasedHistoryForUserResolver } from './core/resolvers/purchaseHistoryForUser.resolve';
import { SchoolsResolver } from './core/resolvers/schools.resolve';
import { AdminRouteGuardService } from './core/route-guards/admin-guard.service';
import { InstructorRouteGuardService } from './core/route-guards/instructor-guard.service';
import { LoggedInGuardService } from './core/route-guards/logged-in-guard.service';
import { StudentGuardService } from './core/route-guards/student-guard.service';
import { FrequentlyAskedQuestionsComponent } from './frequently-asked-questions/frequently-asked-questions.component';
import { FulfillmentComponent } from './fulfillment/fulfillment.component';
import { HomeComponent } from './home/home.component';
import { LoginRedirectComponent } from './login-redirect/login-redirect.component';
import { LogoutRedirectComponent } from './logout-redirect/logout-redirect.component';
import { NbeReviewManualComponent } from './nbe-review-manual/nbe-review-manual.component';
import { NbeReviewWebinarSignupComponent } from './nbe-review-webinar-signup/nbe-review-webinar-signup.component';
import { NbeTutoringSessionsSignupComponent } from './nbe-tutoring-sessions-signup/nbe-tutoring-sessions-signup.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { PurchaseConfirmationComponent } from './purchase-confirmation/purchase-confirmation.component';
import { SessionTimeoutComponent } from './session-timeout/session-timeout.component';
import { RouteComponent } from './shared/route/route.component';
import { SignInSignUpComponent } from './sign-in-sign-up/sign-in-sign-up.component';
import { TermsComponent } from './terms/terms.component';

@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
    parse(url: string): UrlTree {
        return super.parse(url.toLowerCase());
    }
}

const routes: Routes = [
    {
        path: 'admin',
        component: AdminComponent,
        data: { title: 'Administrator View' },
        canActivate: [AdminRouteGuardService],
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
    },
    {
        path: 'home',
        component: HomeComponent,
        data: { title: 'Home' }
    },
    {
        path: '',
        component: HomeComponent,
        data: { title: 'Home' }
    },
    {
        path: 'subscriptions',
        component: RouteComponent,
        data: { title: 'Subscriptions' },
        canActivate: [LoggedInGuardService],
        loadChildren: () => import('./subscriptions/subscriptions.module').then(m => m.SubscriptionsModule)
    },
    {
        path: 'contact-us',
        component: ContactUsComponent,
        data: { title: 'Contact Us' }
    },
    {
        path: 'instructor/:schoolId',
        component: RouteComponent,
        data: { title: 'Instructor' },
        canActivate: [InstructorRouteGuardService],
        loadChildren: () => import('./instructor/instructor.module').then(m => m.InstructorModule)
    },
    {
        path: 'student',
        component: RouteComponent,
        data: { title: 'Student' },
        canActivate: [StudentGuardService],
        loadChildren: () => import('./student/student.module').then(m => m.StudentModule)
    },
    {
        path: 'game',
        component: RouteComponent,
        data: { title: 'Game' },
        canActivate: [StudentGuardService],
        loadChildren: () => import('./game/game.module').then(m => m.GameModule)
    },
    {
        path: 'account',
        component: AccountComponent,
        data: { title: 'Account Details' },
        canActivate: [LoggedInGuardService],
        resolve: {
            purchaseHistory: PurchasedHistoryForUserResolver,
            school: SchoolsResolver,
        },
    },
    {
        path: 'session-timeout',
        component: SessionTimeoutComponent,
        data: { title: 'Session Timeout' }
    },
    {
        path: 'sign-in-sign-up',
        component: SignInSignUpComponent,
        data: { title: 'SignIn/SignUp' }
    },
    {
        path: 'purchase-confirmation',
        component: PurchaseConfirmationComponent,
        data: { title: 'Purchase Confirmation' }
    },
    {
        path: 'error',
        component: PageNotFoundComponent,
        data: { title: '404' }
    },
    {
        path: 'login-redirect',
        component: LoginRedirectComponent,
        data: { title: 'Handling Login Response' }
    },
    {
        path: 'logout-redirect',
        component: LogoutRedirectComponent,
        data: { title: 'Handling Logout Response' }
    },
    {
        path: 'nbe-review-manual',
        component: NbeReviewManualComponent,
        data: { title: 'NBE Review Manual Purchase' }
    },
    {
        path: 'nbe-tutoring-sessions-signup',
        component: NbeTutoringSessionsSignupComponent,
        data: { title: 'NBE Tutoring Sessions Signup' }
    },
    {
        path: 'nbe-review-webinar-signup',
        component: NbeReviewWebinarSignupComponent,
        data: { title: 'NBE Review Webinar Signup' }
    },
    {
        path: 'frequently-asked-questions',
        component: FrequentlyAskedQuestionsComponent,
        resolve: {
            faqs: FaqResolver,
        }
    },
    {
        path: 'privacy',
        component: PrivacyComponent
    },
    {
        path: 'terms',
        component: TermsComponent
    },
    {
        path: 'fulfillment',
        component: FulfillmentComponent
    },
    {
        path: '**',
        component: PageNotFoundComponent,
        data: { title: '404' }
    }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
    imports: [RouterModule.forRoot(
        routes,
        {
    paramsInheritanceStrategy: 'always',
    // enableTracing: true
    // initialNavigation: !isIframe ? 'enabled' : 'disabled',
    anchorScrolling: 'enabled'
})],
    exports: [RouterModule],
    providers: [
        {
            provide: UrlSerializer,
            useClass: LowerCaseUrlSerializer
        }
    ],
})
export class AppRoutingModule { }
