import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { loadPurchasesForCurrentUser } from '../../actions/purchase.actions';
import { PurchaseState } from '../../reducers/purchase.reducers';

@Injectable({
    providedIn: 'root'
})
export class PurchasedHistoryForUserResolver implements Resolve<any> {
    constructor(
        private purchaseStore: Store<PurchaseState>
    ) { }

    resolve() {
        this.purchaseStore.dispatch(loadPurchasesForCurrentUser())
        return;
    }
}
