import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { User } from '../core/models/user.model';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { getCurrentUserFromState, UserState } from '../reducers/user.reducers';
import { isLoadingPurchases } from '../reducers/purchase.reducers';
import { Subscription } from 'rxjs';
import { updateCurrUserDetails, updateCurrUserGradDate as updateCurrUserGradDate } from '../actions/user.actions';

@Component({
    selector: 'dead-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, OnDestroy {
    user: User;
    faEdit = faEdit;
    @ViewChild(DatatableComponent) bulkPurchasesTable: DatatableComponent;

    private readonly subscriptions = new Subscription();

    constructor(private userStore: Store<UserState>) { }

    ngOnInit(): void {
        this.subscriptions.add(
            this.userStore.select(getCurrentUserFromState)
                .subscribe(user => this.user = user)
        );
    }

    updateUser(user: User) {
        this.userStore.dispatch(updateCurrUserDetails({ user }));
        this.user = null;
    }

    updateGradDate(user: User): void {
        this.userStore.dispatch(updateCurrUserGradDate({ user }));
        this.user = null;
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }
}
