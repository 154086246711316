import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'dead-update-grad-date-modal',
    templateUrl: './update-grad-date-modal.component.html',
    styleUrls: ['./update-grad-date-modal.component.scss']
})
export class UpdateGradDateModalComponent implements OnInit {
    faCalendar = faCalendar;
    minDate = new Date(2000, 1, 1);
    dateForm: UntypedFormGroup;
    isOpen = false;
    @Input() graduationDate: Date;

    constructor(
        public activeModal: NgbActiveModal,
        private fb: UntypedFormBuilder,
    ) { }

    ngOnInit(): void {
        this.dateForm = this.fb.group({
            graduationDate: this.fb.control(this.graduationDate ? new Date(this.graduationDate) : null, Validators.required)
        });
    }

    saveChanges(): void {
        if (this.dateForm.valid) {
            this.activeModal.close(this.dateForm.value.graduationDate);
        }
    }
}
