import { Component, OnInit } from '@angular/core';
import { faExternalLinkAlt, faMinus, faPlus, faQuestion, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { debounce, isEmpty, orderBy } from 'lodash';
import { Faq } from '../core/models/faq.model';
import { FaqState, getFaqState } from '../reducers/faq.reducer';

@Component({
    selector: 'dead-frequently-asked-questions',
    templateUrl: './frequently-asked-questions.component.html',
    styleUrls: ['./frequently-asked-questions.component.scss']
})
export class FrequentlyAskedQuestionsComponent implements OnInit {
    allFaqs: Faq[] = [];
    filteredFaqs: Faq[];
    faPlus = faPlus;
    faMinus = faMinus;
    faExternalLink = faExternalLinkAlt;
    faSearch = faSearch;
    faQuestion = faQuestion;
    faqFilter = '';
    isLoading = true;
    debouncedFaqUpdate = debounce(this.onFilterFaq, 500);
    constructor(
        private faqStore: Store<FaqState>
    ) { }

    ngOnInit(): void {
        this.faqStore.select(getFaqState)
            .subscribe({
                next: val => {
                    this.allFaqs = val.faqs;
                    this.filteredFaqs = val.faqs
                    this.isLoading = val.isLoading;
                }
            });

    }

    onFilterFaq(): void {
        let filteredFaqs = this.allFaqs;
        const val = this.faqFilter.toLocaleLowerCase().trim();
        if (!isEmpty(val)) {
            // filter our data
            filteredFaqs = this.allFaqs.filter(p => {
                // search whole words
                return (p.question.toLowerCase().includes(val) ||
                    p.answer.toLowerCase().includes(val)
                )
            });
            if (filteredFaqs.length === 0) {
                let searchResult;
                // try individual keywords
                const keywords = val.split(' ');
                filteredFaqs = this.allFaqs.filter(p => {

                    for (const keyword of orderBy(keywords, 'length', 'desc')) {
                        if (keyword.length > 1) {
                            searchResult = (p.question.toLowerCase().includes(keyword) ||
                                p.answer.toLowerCase().includes(keyword))
                            if (searchResult) {
                                return true;
                            }
                        }
                    }
                    return false;
                });

            }

        }
        // update the rows
        this.filteredFaqs = filteredFaqs;
    }
}
