import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { loadFaqs } from 'src/app/actions/faq.actions';
import { FaqState } from 'src/app/reducers/faq.reducer';


@Injectable({
    providedIn: 'root'
})
export class FaqResolver implements Resolve<any> {
    constructor(private faqStore: Store<FaqState>
    ) { }

    resolve() {
        this.faqStore.dispatch(loadFaqs());
        return;
    }
}
