import { Injectable } from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { environment } from "src/environments/environment";
import { map, mergeMap, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import {PagingTestRun, TestRun, TestRunV2} from "../models/test-run.model";
import { GenerateTest } from "../models/generate-test.model";
import { DbService } from "./db.service";
import {QuestionCategory} from "../models/questionCategory.model";

@Injectable({
    providedIn: "root",
})
export class TestingService {
    testingApiBase = "test/";
    private tableName = "studentTestRuns";

    constructor(private http: HttpClient, private dbService: DbService) {}

    clearCache(): Observable<void> {
        return this.dbService.clearTableData<TestRun>(this.tableName);
    }

    clearTestData(userId: string) {
        return this.http.get(
            `${environment.ADMIN_API_BASE}student/${userId}/clearTestRuns`
        );
    }

    generateTest(getTestModel: GenerateTest): Observable<any> {
        return this.http.post(
            `${environment.STUDENT_API_BASE}${this.testingApiBase}Generate`,
            getTestModel
        );
    }

    generateNBE(type: string, addTimer = false): Observable<any> {
        return this.http.get(
            `${environment.STUDENT_API_BASE}${this.testingApiBase}Generate/NBE/${type}/${addTimer}`
        );
    }

    getStudentTestRun(testRunId: string): Observable<TestRun> {
        return this.dbService.getFilter<TestRun>(
            this.tableName,
            "id",
            testRunId,
            `${environment.STUDENT_API_BASE}${this.testingApiBase}run/${testRunId}`
        );
    }

    getInstructorStudentTestRun(testRunId: string): Observable<TestRun> {
        return this.http
            .get<TestRun>(
                `${environment.INSTRUCTOR_API_BASE}${this.testingApiBase}run/${testRunId}`
            );
    }

    getAllStudentTestRuns(): Observable<TestRun[]> {
        return this.http.get<TestRun[]>(
            `${environment.STUDENT_API_BASE}${this.testingApiBase}runs/all`
        );
    }

    getAllStudentTestRunsCount(): Observable<TestRun[]> {
        return this.http.get<TestRun[]>(
            `${environment.STUDENT_API_BASE}${this.testingApiBase}runscount/all`
        );
    }

    getAllStudentTestRunsCountPaging(page: number, limit = 10): Observable<{ totalCount: number, items: TestRun[] }> {
        return this.http.get<{ totalCount: number, items: TestRun[] }>(`${environment.STUDENT_API_BASE}${this.testingApiBase}runscountpaging/${page}/${limit}`);
    }

    getStudentCategoryBarChartData(): Observable<QuestionCategory[]> {
        return this.http.get<QuestionCategory[]>(`${environment.STUDENT_API_BASE}questionCategory/barchart`);
    }

    getInstructorStudentBarChartData(studentId: string): Observable<QuestionCategory[]> {
        return this.http.get<QuestionCategory[]>(`${environment.INSTRUCTOR_API_BASE}questionCategory/barchart/${studentId}`);
    }

    getStudentCategoryPieChartData(fromDate: string, toDate: string): Observable<QuestionCategory[]> {
        const headers = new HttpHeaders().set('skipSpinner', 'true');
        return this.http.get<QuestionCategory[]>(`${environment.STUDENT_API_BASE}questionCategory/piechart`,
            {
                headers,
                params: {
                    ...(fromDate ? {fromDate} : {}),
                    ...(toDate ? {toDate} : {}),
                }
            }
        );
    }

    getInstructorStudentPieChartData(studentId: string, fromDate: string, toDate: string): Observable<QuestionCategory[]> {
        const headers = new HttpHeaders().set('skipSpinner', 'true');
        return this.http.get<QuestionCategory[]>(`${environment.INSTRUCTOR_API_BASE}questionCategory/piechart/${studentId}`,
            {
                headers,
                params: {
                    ...(fromDate ? {fromDate} : {}),
                    ...(toDate ? {toDate} : {}),
                }
            }
        );
    }

    getAllInstructorStudentTestPaging(studentId: string, page: number, limit = 10): Observable<{ totalCount: number, items: TestRun[] }> {
        return this.http.get<{ totalCount: number, items: TestRun[] }>(`${environment.INSTRUCTOR_API_BASE}${this.testingApiBase}runspaging/all/${studentId}/${page}/${limit}`);
    }

    getCollectionPaging(id: string, page: number, limit = 10): Observable<PagingTestRun> {
        return this.http.get<PagingTestRun>(
            `${environment.STUDENT_API_BASE}question/get/collectionpaging/testrun/${id}/${page}/${limit}`
        );
    }

    getTestResultWithCategory(id: string): Observable<TestRunV2> {
        return this.http.get<TestRunV2>(
            `${environment.STUDENT_API_BASE}${this.testingApiBase}/runsresult/${id}`
        );
    }

    submitStudentTestRunAnswer(id: string, questionId: string, answerId: string): Observable<boolean> {
        return this.http.post<boolean>(
            `${environment.STUDENT_API_BASE}${this.testingApiBase}run/update/answeronly`, {
                id,
                questionId,
                answerId
            }
        );
    }

    getAllInstructorStudentTestRuns(studentId: string): Observable<TestRun[]> {
        return this.http.get<TestRun[]>(
            `${environment.INSTRUCTOR_API_BASE}${this.testingApiBase}runs/all/${studentId}`
        );
    }

    updateStudentTestRunAnswers(testRun: TestRun): Observable<boolean> {
        // returns true if the test run is completed
        return this.http.post<boolean>(
            `${environment.STUDENT_API_BASE}${this.testingApiBase}run/update/answers`,
            testRun
        );
    }

    updateTestRunTime(id: string, seconds: number): Observable<boolean> {
        // returns true if the test run is completed
        const headers = new HttpHeaders().set('skipSpinner', 'true');
        return this.http.post<boolean>(
            `${environment.STUDENT_API_BASE}${this.testingApiBase}run/update/${id}/${seconds}`, {}, { headers });
    }

    getTestRunById(id: string): Observable<any> {
        // returns true if the test run is completed
        return this.http.get<any>(
            `${environment.STUDENT_API_BASE}${this.testingApiBase}run/${id}`);
    }
}
