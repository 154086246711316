<div class="container">
    <div class="d-md-flex align-items-md-center height-100vh--md">
        <div class="container text-center space-2 space-3--lg">
            <div class="w-md-80 w-lg-60 text-center mx-md-auto">
                <div class="mb-5">
                    <fa-icon class="text-primary" size="5x" [icon]="faCheckCircle"></fa-icon>
                    <h1 class="h1 mb-0">Congratulations!</h1>
                    <div *ngIf="!isBulkPurchase">
                        <h3 class="h3">You have activated a {{subscriptionName}} subscription.</h3>
                        <h3 class="h3"><b>Expires: </b>{{subEndDate | date}}</h3>
                        <p>You will receive an email confirmation shortly.</p>
                        <button type="button" class="btn btn-text btn-text-primary cursor-pointer"
                            (click)="goToStudentDashboard()">
                            Check your knowledge <fa-icon [icon]="faArrowRight">
                            </fa-icon>
                        </button>
                    </div>
                    <div *ngIf="isBulkPurchase">
                        <h3 class="h3">You have purchased the {{subscriptionName}} package.</h3>
                        <p *ngIf="!sendCheck">You will receive an email shortly containing your voucher codes.</p>
                        <p *ngIf="sendCheck">You will receive your voucher codes via email once your payment has been
                            received.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
