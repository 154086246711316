import { Injectable } from '@angular/core';
import {
    CanActivate,
    Router, UrlTree
} from '@angular/router';
import { DEADMsalService } from 'src/app/msal/services/dead-msal.service';
import { QuestionCategoryService } from '../services/question-category.service';
import { QuestionService } from '../services/question.service';
import { UserService } from '../services/user.service';
@Injectable({
    providedIn: 'root'
})
export class AdminRouteGuardService implements CanActivate {
    constructor(
        private deadMsalService: DEADMsalService,
        private router: Router,
        private userService: UserService,
        private questionCategoryService: QuestionCategoryService,
        private questionService: QuestionService,
    ) { }

    canActivate(): Promise<boolean> | UrlTree | boolean {
        if (this.deadMsalService.isAdmin()) {
            const url = window.location.href;
            if (url.includes('instructor') || url.includes('student')) {
                return Promise.all([
                    this.userService.clearUserCache().toPromise(),
                    this.questionCategoryService.deleteCache().toPromise(),
                    this.questionService.deleteCache().toPromise(),
                ]).then(() => true);
            }
            return true;
        }
        return this.router.parseUrl('/home');
    }
}
